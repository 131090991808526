import { Component, Fragment } from 'react';
import Alert from '../../../Utility/Alert';
import { getGoogleAuth, enableGoogleAuth } from '../../../../scripts/AuthenticationServices';

export default class Enable2FA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            token: '',
            secretKey: 'henter...',
            barcodeUrl: '',
            message: '',
            failedEnableAuth: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        const response = await getGoogleAuth();
        if (response === 200) {
            const body = response.data;
            this.setState({ secretKey: body.secretKey, barcodeUrl: body.barcodeUrl, failedChangePassword: false });
        }
        this.setState({ loading: false });
    }

    render() {
        const { loading, token, secretKey, barcodeUrl, message, failedEnableAuth } = this.state;
        const googleAuthAndroidDownloadLink = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=da&gl=US';
        const googleAuthIOSDownloadLink = 'https://apps.apple.com/dk/app/google-authenticator/id388497605';
        const microsoftAuthAndroidDownloadLink = 'https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=da&gl=US';
        const microsoftAuthIOSDownloadLink = 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458';
        const qrLink = 'https://qrfree.kaywa.com/?l=1&s=4&d=' + barcodeUrl;

        const submitFun = async (e) => {
            e.preventDefault();
            this.setState({ loading: true, message: '' });
            const response = await enableGoogleAuth(token);
            let msg = 'Din indtastede kode var enten ugyldig eller udløbet';
            if (response.status == 200) {
                msg = 'Succes! Du har nu aktiveret totrinsbekræftiglse';
            }
            this.setState({ loading: false, message: msg, failedEnableAuth: response !== 200 });
        };
        return (
            <Fragment>
                <h4 className="mb-3">Tilføj Totrinsbekræftigelse</h4>

                {this.props.message ? (
                    <div className="alert alert-success">
                        <p>{this.props.message}</p>
                    </div>
                ) : null}

                <h5 className="mb-2">1. Tilføj SmartMail til din autentificerings-App</h5>
                <div className="row mb-3">
                    <div className="col-md-9">
                        <p>Installer enten Google Authenticator eller Microsoft Authenticator App til din smartphone:</p>
                        <ul>
                            <li>
                                Google Authenticator:{' '}
                                <a href={googleAuthAndroidDownloadLink} target="_blank">
                                    {' '}
                                    Android{' '}
                                </a>{' '}
                                eller{' '}
                                <a href={googleAuthIOSDownloadLink} target="_blank">
                                    {' '}
                                    iOS{' '}
                                </a>
                            </li>
                            <li>
                                Microsoft Authenticator:{' '}
                                <a href={microsoftAuthAndroidDownloadLink} target="_blank">
                                    {' '}
                                    Android{' '}
                                </a>{' '}
                                eller{' '}
                                <a href={microsoftAuthIOSDownloadLink} target="_blank">
                                    {' '}
                                    iOS{' '}
                                </a>
                            </li>
                        </ul>

                        <p>Scan QR koden eller indtast følgende kode med din App:</p>
                        <div className="form-group">
                            <input className="form-control" disabled={true} value={secretKey}></input>
                        </div>
                        <img src={qrLink} />
                    </div>
                </div>

                <h5 className="mb-2">2. Bekræft din App med den 6-cifrede kode</h5>
                <Alert isDanger={failedEnableAuth} message={message} hidden={!this.shouldDisplayAlert()} hideFun={() => this.setState({ message: '' })}></Alert>
                <p>På din App kan du nu se at SmartMail er registreret. Indtast den 6 cifrede kode:</p>
                <div className="row">
                    <div className="col-md-3">
                        <form onSubmit={(e) => submitFun(e)}>
                            <div className="form-group">
                                <label>Kode</label>
                                <input
                                    className="form-control"
                                    required
                                    type="number"
                                    defaultValue={token}
                                    onChange={(e) => this.setState({ token: e.target.value })}
                                    disabled={loading}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary-custom" disabled={loading}>
                                Bekræft
                            </button>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }

    shouldDisplayAlert() {
        return this.state.message != '';
    }
}
