import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { IRuleCreation, IRuleDetails } from '../../scripts/ApiTypes';
import { createRule, fetchRuleDetails, updateRule } from '../../scripts/DataServices';
import { FormProgress } from '../Progress/FormProgress';
import { ActionsForm } from './ActionsForm';
import { ConditionsForm } from './ConditionsForm';
import { ConfirmForm } from './ConfirmForm';
import { FallthroughForm } from './FallthroughForm';
import { NameForm } from './NameForm';

export type ConditionFields = {
    field: string;
    action: string;
    parameter: string;
};

export type Condition = {
    allMustHold: boolean;
    field: string;
    action: string;
    parameters: string[];
};

export type Conditions = {
    allMustHold: boolean;
    conditions: Condition[];
};

export type Action = {
    actionType: string;
    parameters: string[];
};

export type RuleFormData = {
    name: string;
    fallthrough: boolean;
    conditions: Conditions;
    actions: Action[];
    exceptions: Conditions;
};

export type RuleFormControls = {
    nextPage: () => void;
    prevPage: () => void;
    setFormData: (f: RuleFormData) => void;
    formData: RuleFormData;
};

export const RuleCreation = () => {
    const { id } = useParams<{ id: string }>();

    const error = (error: any) => {
        if (error instanceof AxiosError && error.response?.status === 404) {
            return <p className="text-lg">Reglen du forsøger at redigere findes ikke</p>;
        } else {
            <p className="text-lg">Der opstod en ukendt fejl da reglen skulle hentes</p>;
        }
    };

    if (!id) {
        return <RuleCreationForm />;
    } else {
        const rule = useQuery(['rule', id], () => fetchRuleDetails(+id));
        return (
            <>
                {rule.error && <div className="shadow-md p-4 flex justify-center items-center">{error(rule.error)}</div>}
                {rule.isLoading && (
                    <div className="shadow-md p-4 flex justify-center items-center">
                        <ClipLoader />
                    </div>
                )}
                {rule.data && <RuleCreationForm rule={rule.data} />}
            </>
        );
    }
};

const RuleCreationForm = ({ rule }: { rule?: IRuleDetails }) => {
    const [formData, setFormData] = useState<RuleFormData>(
        rule
            ? {
                  name: rule.name,
                  fallthrough: rule.fallthrough,
                  conditions: rule.ruleLogic.conditions,
                  actions: rule.ruleLogic.actions,
                  exceptions: rule.ruleLogic.exceptions,
              }
            : {
                  name: '',
                  fallthrough: false,
                  conditions: {
                      allMustHold: false,
                      conditions: [
                          {
                              allMustHold: false,
                              field: 'sender',
                              action: 'contains',
                              parameters: [''],
                          },
                      ],
                  },
                  actions: [
                      {
                          actionType: 'forward_to',
                          parameters: [''],
                      },
                  ],
                  exceptions: {
                      allMustHold: false,
                      conditions: [],
                  },
              }
    );

    const [pageIndex, setPageIndex] = useState<number>(0);

    const pages = [
        <NameForm formData={formData} setFormData={setFormData} nextPage={() => setPageIndex(1)} />,
        <>
            <h2 className="text-center">Tilføj Betingelser</h2>
            <ConditionsForm
                conditions={formData.conditions}
                setConditions={(c: Conditions) => {
                    formData.conditions = c;
                    setFormData({ ...formData });
                }}
                nextPage={() => setPageIndex(2)}
                prevPage={() => setPageIndex(0)}
                minConditions={1}
            />
        </>,
        <ActionsForm
            actions={formData.actions}
            setActions={(a: Action[]) => {
                formData.actions = a;
                setFormData({ ...formData });
            }}
            nextPage={() => setPageIndex(3)}
            prevPage={() => setPageIndex(1)}
        />,
        <>
            <h2 className="text-center">Tilføj Undtagelser</h2>
            <ConditionsForm
                conditions={formData.exceptions}
                setConditions={(c: Conditions) => {
                    formData.exceptions = c;
                    setFormData({ ...formData });
                }}
                nextPage={() => setPageIndex(4)}
                prevPage={() => setPageIndex(2)}
                minConditions={0}
            />
        </>,
        <FallthroughForm
            nextPage={() => setPageIndex(5)}
            prevPage={() => setPageIndex(3)}
            setFallthrough={(b: boolean) => {
                formData.fallthrough = b;
                setFormData({ ...formData });
            }}
        />,
        <ConfirmForm createFunction={rule ? (rc: IRuleCreation) => updateRule(rule.id, rc) : createRule} prevPage={() => setPageIndex(4)} rule={formData} />,
    ];

    return (
        <>
            <FormProgress states={['Navn', 'Betingelser', 'Handlinger', 'Undtagelser', 'Igennemfald', 'Bekræft']} step={pageIndex}></FormProgress>
            <div className="shadow-md p-4 bg-gray-100">{pages[pageIndex]}</div>
        </>
    );
};
