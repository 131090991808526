import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

type FormProps = {
    nextPage: () => void;
    prevPage: () => void;
    setFallthrough: (b: boolean) => void;
};

export const FallthroughForm = ({ nextPage, prevPage, setFallthrough }: FormProps) => {
    return (
        <>
            <h2 className="text-center">Regelmotor fortsættelse</h2>
            <Form>
                <Form.Group className="py-4 border-y-2 mb-4 flex items-center flex-col">
                    <Form.Select className="w-min" onChange={(e) => setFallthrough(!!+e.currentTarget.value)}>
                        <option value="0">Deaktiveret</option>
                        <option value="1">Aktiveret</option>
                    </Form.Select>
                    <p className="text-center w-3/5">
                        Hvis dette er slået til fortsætter SmartMail med at tjekke regler med lavere prioritet selvom en email rammer denne regel. Smartmail
                        eksekvere handlinger for alle de ramte regler.
                    </p>
                </Form.Group>
            </Form>
            <div className="flex justify-between">
                <Button onClick={prevPage} variant="danger">
                    Anullér
                </Button>
                <Button onClick={nextPage}>Næste</Button>
            </div>
        </>
    );
};
