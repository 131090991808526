import Accordion from 'react-bootstrap/Accordion';
import { IRulePriorityChange } from '../../scripts/ApiTypes';
import { EmailDisplay } from '../EmailDisplay/EmailDisplay';
import styles from './PriorityTestResult.module.scss';

export const PriorityTestResults = ({ result }: { result: IRulePriorityChange[] }) => {
    return (
        <>
            <h2>Testresultater</h2>
            {result.length ? (
                <Accordion>
                    {result.map((item, i) => (
                        <Accordion.Item key={i} eventKey={i.toString()}>
                            <Accordion.Header>{item.email.subject}</Accordion.Header>
                            <Accordion.Body>
                                <div className={styles.RuleChangeDiv}>
                                    <p>
                                        Nuværende sortering: <a href={`/rule-details/${item.oldRuleId}`}>{item.oldRuleName}</a>
                                    </p>
                                    <p>Ny sortering: {<a href={`/rule-details/${item.newRuleId}`}>{item.newRuleName}</a> ?? 'AI SmartMail'}</p>
                                </div>
                                <EmailDisplay email={item.email} />
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            ) : (
                <p>Regelfordelingen for denne prioritering er ens i den valgte periode</p>
            )}
        </>
    );
};
