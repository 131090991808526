import { useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import Form from 'react-bootstrap/Form';

type debounceProps = {
    onChange: (s: any) => void;
    defaultValue?: string;
    placeholder?: string;
    className?: string;
};

export const DebouncedInput = ({ onChange, defaultValue, placeholder, className }: debounceProps) => {
    const debouncedEventHandler = useMemo(() => debounce(onChange, 300), []);

    useEffect(() => {
        return () => {
            debouncedEventHandler.cancel();
        };
    }, []);

    return <Form.Control onChange={debouncedEventHandler} placeholder={placeholder} className={className} defaultValue={defaultValue} />;
};
