import { IAuditLog, IEmailItem } from '../../scripts/ApiTypes';
import Accordion from 'react-bootstrap/Accordion';
import { EmailDisplay } from './EmailDisplay';
import styles from './EmailList.module.scss';
import { ColumnDef, getCoreRowModel, useReactTable, getPaginationRowModel } from '@tanstack/react-table';
import { PaginationButtons } from '../Utility/PaginationButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const EmailList = ({ emails, ruleDestination }: { emails: IAuditLog[]; ruleDestination: string | undefined }) => {
    const columns: ColumnDef<IAuditLog>[] = [
        {
            id: 'email',
            accessorFn: (row) => row,
        },
    ];

    const table = useReactTable({
        data: emails,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    return (
        <>
            <Accordion className={styles.EmailList + ' mb-2'}>
                {table.getRowModel().rows.map((row) => (
                    <Accordion.Item eventKey={row.id} key={row.id}>
                        <Accordion.Header>
                            {row.original.email.subject}{' '}
                            {row.original.aiClassification === ruleDestination && <FontAwesomeIcon className="text-amber-300 mx-2" icon={faInfoCircle} />}
                        </Accordion.Header>
                        <Accordion.Body>
                            <EmailDisplay email={row.original.email} />
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <PaginationButtons
                controls={{
                    pageCount: table.getPageCount(),
                    getPageSize: () => table.getState().pagination.pageSize,
                    getCurrentIndex: () => table.getState().pagination.pageIndex,
                    setPageIndex: (i) => table.setPageIndex(i),
                    setPageSize: (i) => table.setPageSize(i),
                }}
            />
        </>
    );
};
