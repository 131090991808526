import axios from 'axios';
import { redirect } from 'react-router-dom';
import Auth from '../authentication/Auth';
import ApiFetcher from './ApiHelpers';

const authApi = axios.create({
    baseURL: '/api/Authenticate/',
    headers: {
        'Content-type': 'application/x-www-form-urlencoded',
    },
});

authApi.interceptors.request.use(
    async (config) => {
        const headers = await ApiFetcher.getHeaders();
        config.headers = headers;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

authApi.interceptors.response.use(
    async (response) => {
        if (response.status === 401) {
            window.location.href = '/login';
        }
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export type passwordChangeResponse = {
    isCurrentPasswordInvalid?: boolean;
    isNewPasswordInvalid?: boolean;
    isConfirmPasswordInvalid?: boolean;
    message?: string;
};

export const SLS = async () => {
    authApi.post('ExternalSingleLogout', new FormData(), {
        headers: { 'Content-type': 'application/x-www-form-urlencoded' },
    });
};

export const requestPasswordChange = async (data: { currentPassword: string; newPassword: string; newPasswordConfirm: string }) => {
    return authApi.post<passwordChangeResponse>('change-password', data);
};

export const requestChangeEmail = async (newEmail: string) => {
    const response = await authApi.post('request-change-email', { newEmail: newEmail, resetPath: 'confirm-email' });
    return response;
};

export const changeEmail = async (data: { newEmail: string; token: string; username: string }) => {
    const response = await authApi.post('change-email', data);
    return response;
};

type googleAuthResponse = {
    secretKey: string;
    barcodeUrl: string;
};

export const getGoogleAuth = async () => {
    const response = await authApi.get<googleAuthResponse>('enable-google-auth');
    return response;
};

export const enableGoogleAuth = async (token: string) => {
    const response = await authApi.post('enable-google-auth', { token: token });
    return response;
};

export const disableGoogleAuth = async () => {
    const response = await authApi.get('disable-google-auth');
    return response;
};

type googleAuthEnabledResponse = {
    isGoogleAuthenticatorEnabled: boolean;
};

export const isGoogleAuthEnabled = async () => {
    const response = await authApi.get<googleAuthEnabledResponse>('google-auth-enabled');
    return response.data;
};

type userDetails = {
    userName: string;
    email: string;
    phoneNumber: string;
    external: boolean;
};

export const getUserDetails = async (): Promise<userDetails> => {
    const response = await authApi.get('user-details');
    return response.data;
};
