import { Component, Fragment } from 'react';
import { disableGoogleAuth } from '../../../../scripts/AuthenticationServices';

export default class Reset2FA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    render() {
        const submitFun = async () => {
            this.setState({ loading: true });
            const response = await disableGoogleAuth();
            this.setState({ loading: false });
            if (response.status === 200) {
                this.props.reset2FAFun();
            }
        };

        return (
            <Fragment>
                <h4 className="mb-3">Nulstilling af 2-Trinsbræftigelse</h4>
                <div className="row mb-3">
                    <div className="col-md-9">
                        <div className="alert alert-warning" role="alert">
                            <p>
                                <strong>Er du sikker på at du vil nulstille totrinsbekræftigelse?</strong>
                            </p>
                        </div>
                        <button className="btn btn-danger" onClick={submitFun}>
                            Nulstil totrinsbekræftigelse
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}
