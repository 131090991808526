import { redirect } from 'react-router-dom';
import ApiFetcher from '../scripts/ApiHelpers';
import { getUserDetails } from '../scripts/AuthenticationServices';

class Auth {
    loggedIn = 0;
    twoFactorRequired = 1;
    failedLogIn = 2;
    lockedOut = 3;

    localStorage = window.localStorage;

    getIsAuthenticated = () => {
        return document.cookie.indexOf('authorized') != -1;
    };

    login = async (username, password, rememberMe) => {
        const data = {
            userName: username,
            password: password,
            rememberMe: rememberMe,
        };

        const method = {
            method: 'POST', // Method itself
            headers: {
                'Content-type': 'application/json; charset=UTF-8', // Indicates the content
            },
            body: JSON.stringify(data), // We send data in JSON format
        };
        const url = 'api/Authenticate/login';

        const response = await fetch(url, method);

        try {
            const body = await response.json();

            if (body.twoFactorRequired) {
                return { status: this.twoFactorRequired, twoFAType: body.type };
            } else if (body.isLockedOut) {
                return { status: this.lockedOut, duration: body.lockoutDuration };
            } else if (!response.ok) {
                return { status: this.failedLogIn };
            }
        } catch {
            await ApiFetcher.getAntiForgeryToken();
            this.localStorage.setItem('authenticated', true);
            //await this.fetchUserDetails();
            return { status: this.loggedIn };
        }
    };

    fetchUserDetails = async () => {
        const data = await getUserDetails();
        this.localStorage.setItem('username', data.userName);
        this.localStorage.setItem('email', data.email);
        this.localStorage.setItem('phoneNumber', data.phoneNumber);
        this.localStorage.setItem('authenticated', true);
    };

    getOrganizationName() {
        return this.localStorage.getItem('organization');
    }

    getUserName() {
        return this.localStorage.getItem('username');
    }

    getEmail() {
        return this.localStorage.getItem('email');
    }

    getPhoneNumber() {
        return this.localStorage.getItem('phoneNumber');
    }

    async getSessionValue(value) {
        if (this.localStorage.getItem(0) === null) {
            await this.fetchUserDetails();
        }
        return this.localStorage.getItem(value);
    }

    twoFactorLogin = async (token, rememberMe) => {
        const data = {
            token: token,
            rememberMe: rememberMe,
        };

        const method = {
            method: 'POST', // Method itself
            headers: {
                'Content-type': 'application/json; charset=UTF-8', // Indicates the content
            },
            body: JSON.stringify(data), // We send data in JSON format
        };
        const url = 'api/Authenticate/two-factor-login';

        const response = await fetch(url, method);
        if (response.ok) {
            await ApiFetcher.getAntiForgeryToken();
            await this.fetchUserDetails();
            return { status: this.loggedIn };
        }

        const body = await response.json();
        if (body.isLockedOut) {
            return { status: this.lockedOut, duration: body.lockoutDuration };
        } else {
            return { status: this.failedLogIn, message: body.message };
        }
    };

    sendForgotPasswordRequest = async (data) => {
        const method = {
            method: 'POST', // Method itself
            headers: {
                'Content-type': 'application/json; charset=UTF-8', // Indicates the content
            },
            body: JSON.stringify(data), // We send data in JSON format
        };
        const url = 'api/Authenticate/forgotten-password';
        const response = await fetch(url, method);
        return response;
    };

    sendResetPasswordRequest = async (data) => {
        const method = {
            method: 'POST', // Method itself
            headers: {
                'Content-type': 'application/json; charset=UTF-8', // Indicates the content
            },
            body: JSON.stringify(data), // We send data in JSON format
        };
        const url = 'api/Authenticate/reset-password';
        const response = await fetch(url, method);
        return response;
    };

    logout = async () => {
        await fetch('/api/authenticate/logout');
        this.localStorage.clear();
        location.reload();
    };
}

export default new Auth(); // returns a singleton
