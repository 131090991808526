import React, { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Condition, Conditions } from './RuleCreation';

type FormProps = {
    nextPage: () => void;
    prevPage: () => void;
    setConditions: (c: Conditions) => void;
    conditions: Conditions;
    minConditions: number;
};

export const ConditionsForm = ({ nextPage, prevPage, setConditions, conditions, minConditions }: FormProps) => {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            nextPage();
        }
        setValidated(true);
    };

    const addCondition = () => {
        conditions.conditions.push({
            allMustHold: false,
            field: 'sender',
            action: 'contains',
            parameters: [''],
        });
        setConditions({ ...conditions });
    };

    const renderConditionInputs = () => {
        const createConditionSelector = (i: number) => {
            return (
                <ConditionSelector
                    key={i}
                    condition={conditions.conditions[i]}
                    setField={(s: string) => {
                        conditions.conditions[i].field = s;
                        setConditions({ ...conditions });
                    }}
                    setAction={(s: string) => {
                        conditions.conditions[i].action = s;
                        setConditions({ ...conditions });
                    }}
                    setParameters={(s: string[]) => {
                        conditions.conditions[i].parameters = s;
                        setConditions({ ...conditions });
                    }}
                    setOperator={(s: boolean) => {
                        conditions.conditions[i].allMustHold = s;
                        setConditions({ ...conditions });
                    }}
                />
            );
        };

        return conditions.conditions.map((c, i) => {
            if (conditions.conditions.length === 1 && minConditions === 1) return createConditionSelector(0);
            if (conditions.conditions.length === 1 && minConditions === 0)
                return (
                    <Fragment key={i}>
                        {createConditionSelector(0)}{' '}
                        <Button
                            className="mb-2"
                            variant="danger"
                            onClick={() => {
                                conditions.conditions.splice(i, 1);
                                setConditions({ ...conditions });
                            }}
                        >
                            Fjern betingelse
                        </Button>
                    </Fragment>
                );
            if (i === 0)
                return (
                    <Fragment key={i}>
                        {createConditionSelector(i)}
                        <Button
                            className="mb-2"
                            variant="danger"
                            onClick={() => {
                                conditions.conditions.splice(i, 1);
                                setConditions({ ...conditions });
                            }}
                        >
                            Fjern betingelse
                        </Button>
                        <Form.Select
                            className="mb-2 w-1/4 mx-auto"
                            onChange={(e) => {
                                conditions.allMustHold = +e.currentTarget.value ? true : false;
                                setConditions({ ...conditions });
                            }}
                            defaultValue={+conditions.allMustHold}
                        >
                            <option value="0">Eller</option>
                            <option value="1">Og</option>
                        </Form.Select>
                    </Fragment>
                );
            if (i !== conditions.conditions.length - 1)
                return (
                    <Fragment key={i}>
                        {createConditionSelector(i)}
                        <Button
                            className="mb-2"
                            variant="danger"
                            onClick={() => {
                                conditions.conditions.splice(i, 1);
                                setConditions({ ...conditions });
                            }}
                        >
                            Fjern betingelse
                        </Button>
                        <Form.Select className="mb-2 w-1/5 mx-auto" disabled>
                            <option>{conditions.allMustHold ? 'Og' : 'Eller'}</option>
                        </Form.Select>
                    </Fragment>
                );
            return (
                <>
                    {createConditionSelector(i)}
                    <Button
                        className="mb-2"
                        variant="danger"
                        onClick={() => {
                            conditions.conditions.splice(i, 1);
                            setConditions({ ...conditions });
                        }}
                    >
                        Fjern betingelse
                    </Button>
                </>
            );
        });
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="py-4 border-y-2 mb-4 flex flex-col items-center">
                <>
                    {renderConditionInputs()}
                    <Button className="mb-2" onClick={addCondition}>
                        Tilføj betingelse
                    </Button>
                </>
            </Form.Group>
            <div className="flex justify-between">
                <Button variant="secondary" onClick={prevPage}>
                    Tilbage
                </Button>
                <Button type="submit">Næste</Button>
            </div>
        </Form>
    );
};

const ConditionSelector = ({
    setField,
    setAction,
    setParameters,
    setOperator,
    condition,
}: {
    setField: (s: string) => void;
    setAction: (c: string) => void;
    setParameters: (s: string[]) => void;
    setOperator: (s: boolean) => void;
    condition: Condition;
}) => {
    const renderInputFields = () => {
        const fields: JSX.Element[] = [];

        condition.parameters.slice(1).forEach((e, i) => {
            fields.push(
                <div className="col-start-1 h-min flex justify-end">
                    <Button
                        onClick={() => {
                            condition.parameters.splice(i + 1, 1);
                            setParameters([...condition.parameters]);
                        }}
                        variant="danger"
                        className="w-1/4"
                    >
                        -
                    </Button>
                </div>
            );
            if (i === 0) {
                fields.push(
                    <Form.Select className="col-start-2 h-min" defaultValue={+condition.allMustHold} onChange={(e) => setOperator(!!+e.currentTarget.value)}>
                        <option value="0">Eller</option>
                        <option value="1">Og</option>
                    </Form.Select>
                );
            } else {
                fields.push(
                    <Form.Select className="col-start-2 h-min" value={+condition.allMustHold} disabled>
                        <option value="0">Eller</option>
                        <option value="1">Og</option>
                    </Form.Select>
                );
            }
            fields.push(
                <Form.Group>
                    <Form.Control
                        value={condition.parameters[i + 1]}
                        required
                        placeholder="værdi..."
                        onChange={(e) => {
                            let params = condition.parameters;
                            params[i + 1] = e.currentTarget.value;
                            setParameters([...condition.parameters]);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">Indtast en værdi eller fjern feltet.</Form.Control.Feedback>
                </Form.Group>
            );
        });
        return fields;
    };

    return (
        <Form.Group className={`grid grid-cols-[repeat(3,_minmax(0,_1fr))_min-content] mb-2 grid-rows-${condition.parameters.length}`}>
            <Form.Select
                className="h-min"
                onChange={(e) => {
                    setField(e.currentTarget.value);
                }}
                defaultValue={condition.field}
            >
                <option value="sender">Afsender</option>
                <option value="recipient">Modtager</option>
                <option value="subject">Emne</option>
                <option value="body">Besked</option>
                <option value="attachment_text">Vedhæftet tekst</option>
                <option value="any_field">Vilkårligt felt</option>
            </Form.Select>
            <Form.Select
                className="h-min"
                onChange={(e) => {
                    setAction(e.currentTarget.value);
                }}
                defaultValue={condition.action}
            >
                <option value="contains">Indeholder</option>
                <option value="starts_with">Starter med</option>
                <option value="ends_with">Ender med</option>
                <option value="equals">Lig med</option>
                <option value="regex">Regex match</option>
            </Form.Select>
            <Form.Group>
                <Form.Control
                    defaultValue={condition.parameters[0]}
                    required
                    placeholder="værdi..."
                    onChange={(e) => {
                        let params = condition.parameters;
                        params[0] = e.currentTarget.value;
                        setParameters([...condition.parameters]);
                    }}
                />
                <Form.Control.Feedback type="invalid">Indtast en værdi eller fjern feltet.</Form.Control.Feedback>
            </Form.Group>
            {renderInputFields()}
            <div className="h-min w-fit">
                <Button
                    onClick={() => {
                        condition.parameters.push('');
                        setParameters(condition.parameters);
                    }}
                >
                    +
                </Button>
            </div>
        </Form.Group>
    );
};
