import React, { Component, Fragment } from 'react';
import Enable2FA from './2FA/Enable2FA';
import Reset2FA from './2FA/Reset2FA';
import { isGoogleAuthEnabled } from '../../../scripts/AuthenticationServices';

export default class TwoFactorAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            didReset2FA: false,
            currentView: 0,
            loading: true,
            isGoogleAuthEnabled: false,
        };
    }

    componentDidMount() {
        this.getAuthEnabledState();
    }

    async getAuthEnabledState() {
        this.setState({ loading: true });
        const response = await isGoogleAuthEnabled();
        this.setState({ loading: false, isGoogleAuthEnabled: response.isGoogleAuthenticatorEnabled });
    }

    getAuthEnabledAlert() {
        const { loading, isGoogleAuthEnabled } = this.state;
        const cls = 'alert alert-' + (isGoogleAuthEnabled ? 'success' : 'warning');
        const msg = isGoogleAuthEnabled
            ? 'Din konto er allerede beskyttet med totrinsbekræftigelse'
            : 'Din konto er kun beskyttet med totrinsbekræftigelse via. din email adresse. Aktiver totrinsbekræftigelse med en App for optimal beskyttelse af din konto.';
        const tag = loading ? null : (
            <div className={cls} role="alert">
                <p>
                    <strong>{msg}</strong>
                </p>
            </div>
        );
        return tag;
    }

    getInnerDisplay() {
        const { currentView, didReset2FA } = this.state;

        switch (currentView) {
            case 0:
                return (
                    <Fragment>
                        <h4 className="mb-3">Totrinsbekræftigelse</h4>
                        <h5 className="mb-3">Her kan du enten aktivere eller deaktivere totrinsbekræftigelse ved login</h5>
                        {this.getAuthEnabledAlert()}
                        <div className="col-md-9">
                            <div className="d-flex justify-content-around">
                                <button className="btn btn-primary-custom" onClick={() => this.setState({ currentView: 1 })}>
                                    Opsæt totrinsbekræftigelse{' '}
                                </button>
                                <button className="btn btn-primary-custom" onClick={() => this.setState({ currentView: 2 })}>
                                    Nulstil totrinsbekræftigelse
                                </button>
                            </div>
                        </div>
                    </Fragment>
                );
            case 1:
                return (
                    <Enable2FA
                        history={this.props.history}
                        message={
                            didReset2FA ? 'Din totrinsbekræftigelse blev nulstillet. Følg nedenstående trin for at aktivere totrinsbekræftigelse igen.' : false
                        }
                    ></Enable2FA>
                );
            case 2:
                return <Reset2FA history={this.props.history} reset2FAFun={() => this.setState({ didReset2FA: true, currentView: 1 })}></Reset2FA>;
        }
    }

    render() {
        return this.getInnerDisplay();
    }
}
