import { NavMenu } from '../NavMenu';
import Container from 'react-bootstrap/Container';

export const Layout = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <NavMenu></NavMenu>
            <Container fluid="xxl">{children}</Container>
        </>
    );
};
