import React, { FormEvent, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Auth from './Auth';
import { redirect, useNavigate } from 'react-router-dom';

export const LoginForm = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [twoFaType, setTwoFaType] = useState<string | null>(null);

    const login = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoggingIn(true);
        const result = await Auth.login(username, password, rememberMe);
        switch (result?.status) {
            case Auth.loggedIn:
                redirect('/');
                break;
            case Auth.twoFactorRequired:
                setTwoFaType(result.twoFAType);
                break;
            case Auth.lockedOut:
                setError('For mange login-forsøg. Kontoen er låst i ' + result.duration + ' minutter.');
                break;
            case Auth.failedLogIn:
                setIsLoggingIn(false);
                setError('Ugyldigt brugernavn eller password');
                break;
            default:
                setIsLoggingIn(false);
                setError('Der opstod en fejl under login');
        }
    };

    return twoFaType ? (
        <TwoFactor rememberMe={rememberMe} twoFAType={twoFaType} />
    ) : (
        <div className="shadow-md p-4">
            <h1 className="text-center">Log in</h1>
            <Form onSubmit={login}>
                <Form.Group>
                    <Form.Label className="text-lg">Brugernavn</Form.Label>
                    <Form.Control className="mb-2" onChange={(e) => setUsername(e.currentTarget.value)} />
                    <Form.Label className="text-lg">Password</Form.Label>
                    <Form.Control type="password" className="mb-2" onChange={(e) => setPassword(e.currentTarget.value)} />
                    <Form.Check className="ml-2" label="Husk mig" onClick={(e) => setRememberMe(e.currentTarget.checked)} />
                </Form.Group>
                <Form.Group className="flex flex-col items-center">
                    {error && <p className="error-message">{error}</p>}
                    <Button type="submit" className="w-1/6 mb-2" disabled={isLoggingIn}>
                        {isLoggingIn ? 'Logger ind...' : 'Login'}
                    </Button>
                    <a className="mb-4" href="/forgot-password">
                        Glemt password?
                    </a>
                    <Button className="w-1/6" href="/api/authenticate/externallogin">
                        Log ind med SSO
                    </Button>
                </Form.Group>
            </Form>
        </div>
    );
};

export const ForgotPassword = () => {
    const [email, setEmail] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [resetLinkSent, setResetLinkSent] = useState<boolean>(false);

    const confirm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        const response = await Auth.sendForgotPasswordRequest({
            email: email,
            resetPath: 'reset-password',
        });
        setResetLinkSent(true);
    };

    return (
        <div className="shadow-md p-4">
            <h1 className="text-center">Nulstil password</h1>
            {!resetLinkSent ? (
                <Form onSubmit={(e) => confirm(e)}>
                    <Form.Group className="mb-2">
                        <Form.Label className="text-lg">Email</Form.Label>
                        <Form.Control
                            required
                            placeholder="Indtast email..."
                            disabled={isLoading}
                            onKeyDown={(e) => e.key === 'Enter' && confirm}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                    </Form.Group>
                    <FormGroup className="flex justify-center">
                        <Button className="w-1/6" type="submit" disabled={isLoading}>
                            {isLoading ? 'Sender...' : 'Send email'}
                        </Button>
                    </FormGroup>
                </Form>
            ) : (
                <>
                    <h5 className="border-bottom mb-3">Vi har nu sendt en email til {email}. Tjek din spam mappe hvis du ikke kan finde emailen.</h5>
                    <div className="confirm-container-bottom">
                        <Button href="/login">Tilbage</Button>
                    </div>
                </>
            )}
        </div>
    );
};

export const TwoFactor = ({ twoFAType, rememberMe }: { twoFAType: string; rememberMe: boolean }) => {
    const navigate = useNavigate();
    const [twoFactorCode, setTwoFactorCode] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<string | null>(null);

    const login = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        const result = await Auth.twoFactorLogin(twoFactorCode, rememberMe);
        switch (result.status) {
            case Auth.lockedOut:
                setIsLoading(false);
                setLoginError('For mange login-forsøg. Kontoen er låst i ' + result.duration + ' minutter.');
                break;
            case Auth.loggedIn:
                setIsLoading(false);
                navigate('/', { replace: true });
                break;
            default:
                setIsLoading(false);
                setLoginError('Ugyldig kode');
                break;
        }
    };

    return (
        <div className="shadow-md p-4">
            <h1>Totrinsgodkendelse påkrævet</h1>
            <p>{twoFAType === 'Email' ? 'Indtast bekræftigelseskoden sendt til din email' : 'Indtast bekræftigelseskoden fra din autentificering App'}</p>
            <Form onSubmit={login}>
                <Form.Group className="mb-2">
                    <Form.Label className="text-lg">Kode:</Form.Label>
                    <Form.Control required type="number" disabled={isLoading} onChange={(e) => setTwoFactorCode(e.currentTarget.value)}></Form.Control>
                </Form.Group>
                <Form.Group className="flex justify-center">
                    <Button disabled={isLoading} type="submit">
                        {isLoading ? 'Sender...' : 'Send kode'}
                    </Button>
                </Form.Group>
            </Form>
            {loginError && <p>Ugyldig kode</p>}
        </div>
    );
};
