import { useQuery } from '@tanstack/react-query';
import { ClipLoader } from 'react-spinners';
import { fetchRules } from '../../scripts/DataServices';
import PriorityTable from './PriorityTable';

const RulePriority = () => {
    const rules = useQuery(['rules'], fetchRules, {
        select: (rules) => rules.sort((r1, r2) => r1.priority - r2.priority),
    });

    return (
        <>
            <h1>Regelprioritering</h1>
            <div>
                <>
                    {rules.error && 'Der opstod en fejl da regler skulle hentes.'}
                    {rules.isLoading && (
                        <div className="w-full flex justify-center">
                            <ClipLoader size={70} />
                        </div>
                    )}
                    {rules.data && <PriorityTable data={rules.data}></PriorityTable>}
                </>
            </div>
        </>
    );
};

export default RulePriority;
