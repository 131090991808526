import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { IStatisticsFilter } from '../../scripts/ApiTypes';
import { fetchPeriodStatistics } from '../../scripts/DataServices';
import { DateRange } from '../Utility/DateRange';
import { BarChart } from './BarChart';
import { PieChart } from './PieChart';
import { StatisticsCountBox } from './StatisticCountBox';
import styles from './Stats.module.scss';
import { testData } from './StatisticTestData';

type statisticsData = {
    id: string;
    value: number;
};

const Statistics = () => {
    const [logScale, setLogScale] = useState<boolean>(false);
    const [modelDistributionView, setModelDistributionView] = useState<boolean>(true);

    const [dateFilter, setDateFilter] = useState<IStatisticsFilter>({
        from: new Date(Date.now() - 24 * 60 * 60 * 1000),
        to: new Date(Date.now()),
    });

    const truncateString = (text: string) => {
        return text.length > 20 ? text.slice(0, 20) + '...' : text;
    };

    const statistics = useQuery(['statistics', dateFilter], () => fetchPeriodStatistics(dateFilter), {
        select: (data) => {
            const calcPercentage = (current: number) =>
                Math.round((current / (data.totalAmountSortedByModel + data.totalAmountSortedByRules + data.totalAmountUnsorted)) * 100);
            let totals = [
                {
                    id: '% Sorteret af AI-model',
                    label: 'Sorteret af AI-model',
                    value: calcPercentage(data.totalAmountSortedByModel),
                },
                {
                    id: '% til manuel behandling',
                    label: 'Manuel behandling',
                    value: calcPercentage(data.totalAmountUnsorted),
                },
                {
                    id: '% Sorteret af regler',
                    label: 'Sorteret af regler',
                    value: calcPercentage(data.totalAmountSortedByRules),
                },
            ];

            let test = testData;
            test.ruleData.sort((a, b) => a.Sorteret - b.Sorteret);
            test.modelData.sort((a, b) => a.Sorteret - b.Sorteret);

            return {
                ...data,
                ruleData: data.ruleSortedMailByCount.map((e) => {
                    return {
                        Id: e.id,
                        Sorteret: e.count,
                        'AI lighed': e.aiCount,
                    };
                }),
                modelData: data.modelSortedMailByCount.map((e) => {
                    return {
                        Id: e.id,
                        Sorteret: e.count,
                    };
                }),
                percentages: totals,
            };
        },
    });

    const dateChange = (startDate: Date, endDate: Date) => {
        setDateFilter({
            from: startDate,
            to: endDate,
        });
    };

    return (
        <>
            <h1>Statistik over SmartMail fordeling</h1>
            <div className="flex justify-start items-end mb-2">
                <Form.Group className="mr-2">
                    <Form.Label>Vælg periode</Form.Label>
                    <DateRange
                        from={dateFilter.from}
                        to={dateFilter.to}
                        setFrom={(d) => {
                            dateFilter.from = d;
                            setDateFilter({ ...dateFilter });
                        }}
                        setTo={(d) => {
                            dateFilter.to = d;
                            setDateFilter({ ...dateFilter });
                        }}
                    />
                </Form.Group>
                <Button onClick={() => dateChange(new Date(Date.now() - 24 * 60 * 60 * 1000), new Date(Date.now()))}>Se statistik for seneste dag</Button>
            </div>
            {statistics.data && (
                <>
                    <div className={styles.totalStatsWrapper + ' shadow-md p-4 bg-gray-100'}>
                        <div className={styles.totalStats}>
                            <StatisticsCountBox count={statistics.data.totalAmountHandled} description={'Fordelt i alt'} />
                            <StatisticsCountBox
                                count={statistics.data.totalAmountHandled - statistics.data.totalAmountUnsorted}
                                description={'Antal sorteret'}
                            />
                            <StatisticsCountBox count={statistics.data.totalAmountUnsorted} description={'Antal usorteret'} />
                            <StatisticsCountBox
                                count={
                                    (
                                        ((statistics.data.totalAmountHandled - statistics.data.totalAmountUnsorted) / statistics.data.totalAmountHandled) *
                                        100
                                    ).toFixed(2) + '%'
                                }
                                description={'Procent Sorteret'}
                            />
                        </div>
                        <div>
                            <PieChart data={statistics.data.percentages} />
                        </div>
                    </div>
                    <div className="shadow-md p-4 bg-gray-100">
                        <Form>
                            <Form.Check
                                inline
                                checked={modelDistributionView}
                                type="radio"
                                label="Modelfordeling"
                                name="fordeling"
                                onChange={() => setModelDistributionView(!modelDistributionView)}
                            />
                            <Form.Check
                                inline
                                checked={!modelDistributionView}
                                type="radio"
                                label="Regelfordeling"
                                name="fordeling"
                                onChange={() => setModelDistributionView(!modelDistributionView)}
                            />
                            <Form.Check inline type="switch" id="custom-switch" label="Logaritmisk skala" onChange={() => setLogScale(!logScale)} />
                        </Form>
                        <BarChart
                            logScale={logScale}
                            data={modelDistributionView ? statistics.data.modelData : statistics.data.ruleData}
                            ruleView={!modelDistributionView}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default Statistics;
