import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';

interface PaginationControls {
    pageCount: number;
    getPageSize: () => number;
    getCurrentIndex: () => number;
    setPageIndex: (i: number) => void;
    setPageSize: (i: number) => void;
}

export const PaginationButtons = ({ controls }: { controls: PaginationControls }) => {
    const nextPage = () => controls.setPageIndex(controls.getCurrentIndex() + 1);
    const prevPage = () => controls.setPageIndex(controls.getCurrentIndex() - 1);

    const makePaginationButtons = () => {
        if (controls.pageCount <= 5) {
            return [...Array(controls.pageCount).keys()].map((i) => (
                <Pagination.Item key={i} active={controls.getCurrentIndex() === i} onClick={() => controls.setPageIndex(i)}>
                    {i + 1}
                </Pagination.Item>
            ));
        } else if (controls.getCurrentIndex() <= 3) {
            return (
                <>
                    {[0, 1, 2, 3, 4].map((i) => (
                        <Pagination.Item key={i} active={controls.getCurrentIndex() === i} onClick={() => controls.setPageIndex(i)}>
                            {i + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Ellipsis />
                    <Pagination.Item onClick={() => controls.setPageIndex(controls.pageCount - 1)}>{controls.pageCount}</Pagination.Item>
                </>
            );
        } else if (controls.pageCount - controls.getCurrentIndex() <= 3) {
            return (
                <>
                    <Pagination.Item onClick={() => controls.setPageIndex(0)}>1</Pagination.Item>
                    <Pagination.Ellipsis />
                    {[controls.pageCount - 4, controls.pageCount - 3, controls.pageCount - 2, controls.pageCount - 1, controls.pageCount].map((i) => (
                        <Pagination.Item key={i} active={controls.getCurrentIndex() === i - 1} onClick={() => controls.setPageIndex(i)}>
                            {i}
                        </Pagination.Item>
                    ))}
                </>
            );
        } else {
            return (
                <>
                    <Pagination.Item onClick={() => controls.setPageIndex(0)}>1</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item onClick={prevPage}>{controls.getCurrentIndex()}</Pagination.Item>
                    <Pagination.Item active>{controls.getCurrentIndex() + 1}</Pagination.Item>
                    <Pagination.Item onClick={nextPage}>{controls.getCurrentIndex() + 2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item onClick={() => controls.setPageIndex(controls.pageCount - 1)}>{controls.pageCount}</Pagination.Item>
                </>
            );
        }
    };
    return (
        <div className="flex">
            <Form.Select
                defaultValue={controls.getPageSize()}
                className="mr-2 h-min w-min"
                onChange={(e) => {
                    controls.setPageSize(+e.currentTarget.value);
                }}
            >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
            </Form.Select>
            <Pagination>
                <Pagination.Prev disabled={controls.getCurrentIndex() === 0} onClick={prevPage} />
                {makePaginationButtons()}
                <Pagination.Next disabled={controls.getCurrentIndex() === controls.pageCount - 1} onClick={nextPage} />
            </Pagination>
        </div>
    );
};
