import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useState } from 'react';
import { IDestination } from '../../scripts/ApiTypes';
import { faCheckCircle, faExclamationCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { deleteSharedMailbox, getMailboxConnection } from '../../scripts/DataServices';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ClipLoader } from 'react-spinners';

const ReachableIcon = ({ address }: { address: string }) => {
    const reachable = useQuery(['reachable', address], async () => await getMailboxConnection(address));

    return (
        <>
            {reachable.isLoading && <ClipLoader size={20} />}
            {reachable.data === true && <FontAwesomeIcon color="green" icon={faCheckCircle} />}
            {reachable.data === false && <FontAwesomeIcon color="red" icon={faExclamationCircle} />}
        </>
    );
};

export const SharedMailBoxTable = ({ mailboxes }: { mailboxes: IDestination[] }) => {
    const columns: ColumnDef<IDestination>[] = [
        {
            accessorFn: (row) => row.mailbox,
            id: 'mailbox',
            header: 'Postkasse',
        },
        {
            cell: (props) => <ReachableIcon address={props.row.original.mailbox}></ReachableIcon>,
            id: 'reachable',
            header: 'Forbindelsesstatus',
        },
        {
            accessorFn: (row) => (row.aiAccessible ? 'Ja' : 'Nej'),
            id: 'ai',
            header: 'Registreret i AI-model',
        },
        {
            cell: (props) => <DeleteModal mailboxName={props.row.original.mailbox} deleteMailbox={() => deleteSharedMailbox(props.row.original.id)} />,
            id: 'delete',
        },
    ];

    const table = useReactTable({
        data: mailboxes,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Table className="shadow-md m-2 bg-gray-100">
            <colgroup>
                <col span={1} style={{ width: '30%' }} />
                <col span={1} style={{ width: '30%' }} />
                <col span={1} style={{ width: '20%' }} />
                <col span={1} style={{ width: '20%' }} />
            </colgroup>
            <thead className="bg-main-500 text-gray-100">
                <tr>
                    {table.getFlatHeaders().map((header) => (
                        <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                    ))}
                </tr>
            </thead>
            <tbody className="align-middle">
                {table.getCoreRowModel().rows.map((row) => (
                    <tr>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

type deleteModalProps = {
    mailboxName: string;
    deleteMailbox: () => Promise<number>;
};

const DeleteModal = ({ mailboxName, deleteMailbox }: deleteModalProps) => {
    const queryClient = useQueryClient();

    const [show, setShow] = useState<boolean>(false);

    const handleDelete = () => {
        deleteMailbox().then((res) => {
            if (res === 204) {
                queryClient.invalidateQueries(['mailboxes']);
                handleClose();
            } else {
                window.alert('Der opstod en fejl da mailboxen skulle fjernes');
            }
        });
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="secondary" onClick={handleShow}>
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Fjern postkasse</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Er du sikker på at du vil fjerne postkassen {mailboxName} fra SmartMail? Hvis postkassen bliver brugt af AI modellen vil mails til denne
                    postkasse gå til manuel behandling.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fortryd
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Fjern postkasse
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
