import { Component } from 'react';
import Auth from './Auth';
import './LoginPage.scss';
import Alert from '../components/Utility/Alert';

export class ResetPassword extends Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);

        this.state = {
            isLoading: false,
            password: '',
            passwordConfirm: '',
            email: query.get('email'),
            token: query.get('token'),
            result: null,
            didFailPasswordReset: false,
            hasMadePasswordResetRequest: false,
            message: '',
        };
    }

    render() {
        const { didFailPasswordReset, hasMadePasswordResetRequest } = this.state;
        const title = hasMadePasswordResetRequest && !didFailPasswordReset ? 'Succes' : 'Nulstil dit Password';
        return (
            <div className="background">
                <div className="container shadow-sm relative-container">
                    <h2 className="mb-4 pt-4">{title}</h2>
                    <>{hasMadePasswordResetRequest && !didFailPasswordReset ? this.resultPage() : this.enterPasswordPage()}</>
                </div>
            </div>
        );
    }

    resultPage() {
        return (
            <div className="confirm-container">
                <div className="confirm-container-middle">
                    <h5 className="border-bottom mb-3">{'Dit password blev nulstillet!'}</h5>
                </div>

                <div className="confirm-container-bottom">
                    <a className="btn btn-primary-custom mb-3" href="/login">
                        Til Login
                    </a>
                </div>
            </div>
        );
    }

    enterPasswordPage() {
        const { password, passwordConfirm, isLoading, email, token, result, message } = this.state;
        let failedPasswordReset = false;
        const confirmFun = async (e) => {
            e.preventDefault();
            const data = {
                password: password,
                passwordConfirm: passwordConfirm,
                email: email,
                token: token,
            };
            this.setState({ isLoading: true });
            const response = await Auth.sendResetPasswordRequest(data);
            let result = null;
            let msg = '';
            try {
                result = await response.json();
                if (!response.ok) {
                    msg = result.message;
                    failedPasswordReset = true;
                }
            } catch {}
            this.setState({ isLoading: false, result: result, hasMadePasswordResetRequest: true, didFailPasswordReset: failedPasswordReset, message: msg });
        };

        const handleKeyDown = async (e) => {
            if (e.key === 'Enter') {
                await confirmFun(e);
            }
        };

        return (
            <div className="login-container">
                <Alert isDanger={true} message={this.formatMessage(message)} hidden={message === ''} hideFun={() => this.setState({ message: '' })}></Alert>

                <form id="lostPasswordForm" onSubmit={(e) => confirmFun(e)}>
                    <div className="form-group mb-3">
                        <label>Nyt Password</label>
                        <input
                            type="password"
                            className="form-control"
                            required
                            placeholder="Indtast nyt password..."
                            aria-describedby="passwordHelp"
                            disabled={isLoading}
                            onKeyDown={handleKeyDown}
                            value={password}
                            onChange={(event) => this.setState({ password: event.target.value })}
                        />
                    </div>

                    <div className="form-group mb-3">
                        <label>Gentag Password</label>
                        <input
                            type="password"
                            className="form-control"
                            required
                            placeholder="Gentag nyt password"
                            aria-describedby="passwordHelp"
                            disabled={isLoading}
                            onKeyDown={handleKeyDown}
                            value={passwordConfirm}
                            onChange={(event) => this.setState({ passwordConfirm: event.target.value })}
                        />
                    </div>
                </form>

                <input
                    type="submit"
                    form="lostPasswordForm"
                    className="btn btn-primary-custom mb-3"
                    disabled={isLoading}
                    value={isLoading ? 'Nulstiller...' : 'Nulstil'}
                />
            </div>
        );
    }

    formatMessage(message) {
        const strList = message.trim().split('\n');
        if (strList.length > 1) {
            return (
                <ul className="mb-0">
                    {strList.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul>
            );
        }
        return message;
    }
}
