import { IDestination } from '../../scripts/ApiTypes';

export const MailBoxCard = ({ mailbox }: { mailbox: IDestination }) => {
    return (
        <div className="my-2 bg-gray-100 p-4 drop-shadow-md flex justify-between w-full">
            <div>
                <h2 className="text-lg font-semibold">{mailbox.mailbox}</h2>
                <p className="mb-0">{mailbox.aiAccessible ? 'Inkluderet i AI fordeling' : 'Ekskluderet fra AI fordeling'}</p>
            </div>
            <div className="text-right">
                <p className={mailbox.registeredMail > 1000 ? 'text-main-500' : 'text-yellow-700'}>{mailbox.registeredMail} registrerede mails til træning</p>
                {mailbox.reachable ? (
                    <p className="mb-0 text-main-500">SmartMail har forbindelse til postkassen</p>
                ) : (
                    <p className="mb-0 text-red-500">SmartMail har ikke forbindelse til postkassen</p>
                )}
            </div>
        </div>
    );
};
