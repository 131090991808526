import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { FC, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { ConnectableElement, DndProvider, useDrag, useDrop, XYCoord } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IRule } from '../../scripts/ApiTypes';
import { putRulePriorities, testPriorityChange } from '../../scripts/DataServices';
import { useQuery } from '@tanstack/react-query';
import { PriorityTestResults } from './PriorityTestResults';
import FadeLoader from 'react-spinners/ClipLoader';
import { DateRange } from '../Utility/DateRange';

const DraggableRow: FC<{ row: Row<IRule>; reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void }> = ({ row, reorderRow }) => {
    const [{ isOver, offset }, dropRef] = useDrop({
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            offset: monitor.getDifferenceFromInitialOffset(),
        }),
        accept: 'row',
        drop: (draggedRow: Row<IRule>) => reorderRow(draggedRow.index, row.index),
    });

    const [{ isDragging }, dragRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: 'row',
    });

    const dragDropRef = (element: ConnectableElement) => {
        dragRef(element);
        dropRef(element);
    };

    const setBorderType = (offset: XYCoord) => {
        if (offset.y < 0) {
            return { borderTop: '1px solid black' };
        } else {
            return { borderBottom: '1px solid black' };
        }
    };

    return (
        <tr ref={dragDropRef} style={{ opacity: isDragging ? 0.5 : 1 }} className="hover:bg-gray-200">
            {row.getVisibleCells().map((cell) => (
                <td key={cell.id} style={isOver && offset ? setBorderType(offset) : {}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    );
};

const PriorityTable = ({ data }: { data: IRule[] }) => {
    const [items, setItems] = useState<IRule[]>(data);
    const [testFrom, setTestFrom] = useState<Date>(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    const [testTo, setTestTo] = useState<Date>(new Date(Date.now()));
    const [showToast, setShowToast] = useState<{ show: boolean; variant: string }>();

    const priorityTest = useQuery(
        ['priority-test', items],
        () =>
            testPriorityChange({
                ruleIds: items.map((r) => r.id),
                from: testFrom,
                to: testTo,
            }),
        {
            enabled: false,
        }
    );

    const columns: ColumnDef<IRule>[] = [
        {
            accessorFn: (row) => row.priority + 1,
            id: 'priority',
            header: 'Nr',
        },
        {
            accessorFn: (row) => row.name,
            id: 'name',
            header: 'Navn',
        },
        {
            accessorFn: (row) => (row.fallthrough ? 'Aktiveret' : 'Deaktiveret'),
            id: 'ruleText',
            header: 'Regelmotor fortsættelse',
        },
    ];

    const table = useReactTable({
        data: items,
        columns,
        state: {
            sorting: [
                {
                    id: 'priority',
                    desc: false,
                },
            ],
        },
        getCoreRowModel: getCoreRowModel(),
    });

    const reorderRow = (draggedRowIndex: number, targetRowIndex: number) => {
        data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0] as IRule);
        setItems([...data]);
    };

    const savePriorities = () => {
        const res = putRulePriorities(items.map((r) => r.id));
        res.then((status) => {
            if (status === 200) {
                setShowToast({ show: true, variant: 'success' });
            } else {
                setShowToast({ show: true, variant: 'danger' });
            }
        });
    };

    return (
        <>
            {showToast && (
                <Alert variant="success" color={showToast.variant}>
                    Regelprioriteringen er registreret og gemt!
                </Alert>
            )}
            <DndProvider backend={HTML5Backend}>
                <div className="shadow-sm">
                    <Table className="border-separate border-spacing-0 bg-gray-100">
                        <thead className="bg-main-500 text-gray-100">
                            <tr>
                                {table.getFlatHeaders().map((header) => (
                                    <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <DraggableRow key={row.id} row={row} reorderRow={reorderRow} />
                            ))}
                        </tbody>
                    </Table>
                </div>
            </DndProvider>
            <Button className="mb-2" onClick={savePriorities}>
                Gem rækkefølge
            </Button>
            <p>Test ændringer i perioden:</p>
            <div className="flex mb-2">
                <DateRange to={testTo} from={testFrom} setTo={setTestTo} setFrom={setTestFrom} />
                <Button className="ml-2" onClick={() => priorityTest.refetch()}>
                    Test ændringer
                </Button>
            </div>
            {priorityTest.isLoading && priorityTest.fetchStatus !== 'idle' && <FadeLoader />}
            {priorityTest.data && <PriorityTestResults result={priorityTest.data} />}
        </>
    );
};

export default PriorityTable;
