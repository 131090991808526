import { IRuleJson } from '../../scripts/ApiTypes';
import { Action, Condition } from './RuleCreation';

const parseParameters = (parameters: string[], allMustHold: boolean): string => {
    if (parameters.length === 1) return parameters[0];

    if (parameters.length === 2) return `${parameters[0]} ${allMustHold ? 'og' : 'eller'} ${parameters[1]}`;

    return `${parameters.slice(0, parameters.length - 1).join(', ')} ${allMustHold ? 'og' : 'eller'} ${parameters[parameters.length - 1]}`;
};

const parseAction = (condition: string): string => {
    switch (condition) {
        case 'starts_with':
            return 'starter med';
        case 'ends_with':
            return 'ender med';
        case 'equals':
            return 'er identisk med';
        case 'contains':
            return 'indeholder';
        case 'regex':
            return 'matcher på regex udtrykket:';
        default:
            throw new Error('Unhandled switch case: ' + condition);
    }
};

const parseField = (field: string): string => {
    switch (field) {
        case 'sender':
            return 'afsenderfeltet';
        case 'recipient':
            return 'modtagerfeltet';
        case 'subject':
            return 'emnefeltet';
        case 'body':
            return 'selve beskeden';
        case 'forward_to':
            return 'send mailen til';
        case 'move_to_folder':
            return 'flyt mailen til mappen';
        case 'copy_to_folder':
            return 'kopier mailen til mappen';
        case 'move_to_sub_mailbox':
            return 'flyt mailen til underpostkassen';
        case 'copy_to_sub_mailbox':
            return 'kopier mailen til underpostkassen';
        case 'delete_email':
            return 'slet mailen';
        case 'any_field':
            return 'et vilkårligt felt';
        case 'attachment_text':
            return 'vedhæftets fils tekst';
        default:
            throw new Error('Unhandled switch case: ' + field);
    }
};

const pareseCondition = (condition: Condition): string => {
    return `${parseField(condition.field)} ${parseAction(condition.action)} ${parseParameters(condition.parameters, condition.allMustHold)}`;
};

const parseActions = (actions: Action): string => {
    return `${parseField(actions.actionType)} ${parseParameters(actions.parameters, true)}`;
};

export const getRuleDescription = (rule: IRuleJson): string => {
    let conditionsList = rule.conditions.conditions.map((c) => pareseCondition(c));
    let conditions = parseParameters(conditionsList, rule.conditions.allMustHold);
    let actions = parseParameters(
        rule.actions.map((a) => parseActions(a)),
        true
    );
    let excpetions = parseParameters(
        rule.exceptions.conditions.map((c) => pareseCondition(c)),
        rule.exceptions.allMustHold
    );
    return `Hvis ${conditions}, så ${actions}${rule.exceptions?.conditions.length > 0 ? ', undtagen hvis ' + excpetions : ''}.`;
};
