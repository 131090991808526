import { testRuleImpact } from '../../scripts/DataServices';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { IRuleCreation, IRuleTest } from '../../scripts/ApiTypes';
import { EmailList } from '../EmailDisplay/EmailList';
import { DateRange } from '../Utility/DateRange';
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const RuleTest = ({ rule }: { rule: IRuleCreation }) => {
    const [ruleTest, setRuleTest] = useState<IRuleTest>({
        rule: rule,
        fromDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        toDate: new Date(Date.now()),
    });

    const ruleDestination: string | undefined =
        rule.ruleJson.actions.length === 1 && rule.ruleJson.actions[0].parameters.length === 1 ? rule.ruleJson.actions[0].parameters[0] : undefined;

    const ruleHits = useQuery(['rulehits', ruleTest], () => testRuleImpact(ruleTest));

    return (
        <div className="mb-4">
            <h3 className="text-center text-lg font-semibold">Test regel-hits i perioden:</h3>
            <DateRange
                className="w-fit m-auto mb-4"
                from={ruleTest.fromDate}
                to={ruleTest.toDate}
                setFrom={(d) => {
                    ruleTest.fromDate = d;
                    setRuleTest({ ...ruleTest });
                }}
                setTo={(d) => {
                    ruleTest.toDate = d;
                    setRuleTest({ ...ruleTest });
                }}
            />
            {ruleHits.isLoading ? (
                <div className="flex items-center flex-col">
                    <ClipLoader />
                    <p>Tjekker regel imod tidligere mails</p>
                </div>
            ) : (
                ''
            )}
            {ruleHits.data ? (
                <>
                    <p className="text-center">
                        Denne regel ville have sorteret {ruleHits.data.length} {ruleHits.data.length === 1 ? 'mail' : 'mails'} i den valgte periode:
                    </p>

                    <p className="text-center">
                        For {ruleDestination ? ruleHits.data.filter((r) => r.aiClassification === ruleDestination).length : 0} af beskederne ville AI modellen
                        have truffet samme beslutning. Disse er markeret med <FontAwesomeIcon className="text-amber-300" icon={faInfoCircle} />.
                    </p>

                    <div style={{ marginTop: '5px' }}>
                        <EmailList ruleDestination={ruleDestination} emails={ruleHits.data}></EmailList>
                    </div>
                </>
            ) : (
                ''
            )}
            {ruleHits.isError && (
                <div>
                    <p>Der opstod en netværksfejl under regeltesten. Reglen kan stadigvæk gemmes.</p>
                </div>
            )}
        </div>
    );
};
