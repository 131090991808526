const generateValues = (name: string) => {
    const random = Math.floor(Math.random() * 100);
    const ai = Math.floor(Math.random() * random);

    return {
        Id: name,
        Sorteret: random,
        'AI lighed': ai,
    };
};

export const testData = {
    percentages: [
        {
            id: '% Sorteret af AI-model',
            label: 'Sorteret af AI-model',
            value: 52,
        },
        {
            id: '% til manuel behandling',
            label: 'Manuel behandling',
            value: 9,
        },
        {
            id: '% Sorteret af regler',
            label: 'Sorteret af regler',
            value: 39,
        },
    ],
    ruleData: [
        generateValues('Dafolo Borgerblanket: AB505'),
        generateValues('Underretning uden politi'),
        generateValues('Kommunal medfinansiering af boligstøtte'),
        generateValues('Ejendomsskattebillet'),
        generateValues('Geodatastyrelsen'),
        generateValues('Periodisk syn'),
        generateValues('Kontoudtog Sanistaal'),
        generateValues('Advisering om udbetaling pr'),
        generateValues('Opgørelse over børnebidrag'),
        generateValues('Dafolo Borgerblanket: AB405'),
        generateValues('Skifteretten omkring begravelseshjælp'),
        generateValues('Skifteretten, alle undtagen begravelseshjælp'),
        generateValues('Arbejdsmarkedets Erhvervsikring, afgørelse'),
        generateValues('Arbejdsmarkedets Erhvervsikring, autogenereret'),
        generateValues('Indskudslån'),
        generateValues('Sygedagpenge'),
        generateValues('Sygedagpenge, autogenereret mail'),
        generateValues('Børneattester'),
        generateValues('Straffeattester'),
    ],
    modelData: [
        {
            Id: 'afd_skole',
            Sorteret: Math.floor(Math.random() * 100),
        },
        {
            Id: 'afd_social',
            Sorteret: Math.floor(Math.random() * 100),
        },
        {
            Id: 'afd_teknik',
            Sorteret: Math.floor(Math.random() * 100),
        },
    ],
    totalAmountHandled: 1876,
    totalAmountUnsorted: 213,
};
