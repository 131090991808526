import { ResponsiveStream } from '@nivo/stream';

export type modelDataEntry = {
    Korrekt: number;
    Inkorrekt: number;
    Manuel: number;
};

export const ThresholdStackedGraph = ({ data }: { data: modelDataEntry[] }) => {
    return (
        <div style={{ height: '500px' }}>
            <h3 className="text-center">Samlet postfordeling for AI grænseværdier</h3>
            <ResponsiveStream
                data={data}
                keys={['Manuel', 'Korrekt', 'Inkorrekt']}
                margin={{ top: 20, right: 110, bottom: 100, left: 60 }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'AI grænseværdi i %',
                    legendOffset: 35,
                    legendPosition: 'middle',
                    tickValues: Array.from(
                        Array.from({ length: 21 }, (_, i) => i),
                        (e) => e * 5
                    ),
                }}
                axisLeft={{
                    tickSize: 1,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Fordelingsprocent',
                    legendOffset: -40,
                    legendPosition: 'middle',
                }}
                enableGridX={true}
                valueFormat=" >-.2%"
                offsetType="none"
                colors={['#A7C7E7', '#77DD77', '#FF6961']}
                fillOpacity={0.5}
                borderWidth={1}
                borderColor="#a1a1a1"
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        translateX: 100,
                        itemWidth: 80,
                        itemHeight: 20,
                        itemTextColor: '#999999',
                        symbolSize: 12,
                        symbolShape: 'square',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000000',
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};
