export const Unauthorized = () => {
    return (
        <div className="shadow-md p-4 text-center">
            <h1>Manglende rettigheder</h1>
            <p className="w-1/2 m-auto mb-2">
                Din bruger har ikke de nødvendige rettigheder til at tilgå SmartMail. Kontakt din organisations rolleadministrator for at anmode om adgang
            </p>
            <a href="login">Gå tilbage til login siden</a>
        </div>
    );
};
