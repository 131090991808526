import { Tab, Tabs } from 'react-bootstrap';
import { SortingTestUpload } from './SortingTestUpload';
import { SortingTestForm } from './SortingTestForm';

export const SortingTest = () => {
    return (
        <>
            <h1>Sorteringstest</h1>
            <p>
                Her kan du teste hvordan både dine regler og AI model ville sortere en vilkårlig mail, hvis den bliver indsendt til din hovedpostkasse. Udfyld
                felterne som de ville være i mailen, eller upload en gemt mail i .msg format.
            </p>
            <Tabs defaultActiveKey="manual">
                <Tab className="pt-2" eventKey="manual" title="Indtast manuelt">
                    <SortingTestForm></SortingTestForm>
                </Tab>
                <Tab className="pt-2" eventKey="file" title="Upload fil">
                    <SortingTestUpload></SortingTestUpload>
                </Tab>
            </Tabs>
        </>
    );
};
