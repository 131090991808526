import Auth from '../authentication/Auth';

class ApiFetcher {
    getHeaders = async (contentType = 'application/json; charset=UTF-8') => {
        const csrf = this.getCookie('CSRF-Token');
        let headers = {};
        if (csrf) {
            headers['CSRF-Token'] = csrf;
        } else {
            await this.getAntiForgeryToken();
            return await this.getHeaders();
        }
        return headers;
    };

    getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    async handleUnauthorized(responseStatus) {
        if (responseStatus === 401) {
            Auth.logout();
        }
    }

    async getAntiForgeryToken() {
        const url = 'api/antiforgery/';
        const response = await fetch(url);
        this.handleUnauthorized(response.status);
        return response;
    }
}

export default new ApiFetcher(); // returns a singleton
