import { ISmartMailTestResults } from '../../scripts/ApiTypes';
import { getRuleDescription } from '../RuleCreation/RuleSummation';

export const SortingTestResult = ({ aiRecipient, aiConfidence, ruleHits, aiThreshhold }: ISmartMailTestResults) => {
    return (
        <div className="flex">
            <div
                className={
                    'p-2 w-2/5 m-2 shadow-md border-2 ' +
                    (aiConfidence && aiThreshhold && aiConfidence > aiThreshhold ? 'border-emerald-300' : 'border-yellow-300')
                }
            >
                <h2>AI Resultat:</h2>
                <table>
                    <tr>
                        <td className="pr-4">Sortering:</td>
                        <td>{aiRecipient ?? 'AI modulet er ikke tilgængeligt på nuværende tidspunkt.'}</td>
                    </tr>
                    {aiConfidence && aiThreshhold && (
                        <>
                            <tr>
                                <td className="pr-4">AI konfidensniveau:</td>
                                <td>{(aiConfidence * 100).toFixed(2)}%</td>
                            </tr>
                            <tr>
                                <td className="pr-4">AI grænseværdi:</td>
                                <td>{(aiThreshhold * 100).toFixed(2)}%</td>
                            </tr>
                        </>
                    )}
                </table>
            </div>
            <div className={'p-2 m-2 w-3/5 shadow-md border-2 ' + (ruleHits.length === 0 ? 'border-yellow-300' : 'border-emerald-300')}>
                <h2>Regler:</h2>
                {ruleHits.length > 0 ? (
                    <table>
                        {ruleHits.map((r) => (
                            <>
                                <tr>
                                    <td className="pr-4">Navn:</td>
                                    <td>{r.name}</td>
                                </tr>
                                <tr>
                                    <td className="pr-4">Beskrivelse:</td>
                                    <td>{getRuleDescription(r.ruleLogic)}</td>
                                </tr>
                                <tr className="pr-4">
                                    <td>Detaljer:</td>
                                    <td>
                                        <a className="text-blue-600" href={'/rule-details/' + r.id}>
                                            Gå til regelside
                                        </a>
                                    </td>
                                </tr>
                            </>
                        ))}
                    </table>
                ) : (
                    <p>Denne mail ville ikke have været sorteret af nogle af de eksisterende regler.</p>
                )}
            </div>
        </div>
    );
};
